import { AxiosResponse } from 'axios'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import fileDownload from 'js-file-download'

import {
  Ellipsis,
  getFormattedDate,
  getFormattedDateAndTime,
  IconAttach,
  IconChain,
  IconFire,
  IconQuestion,
  IconSignIn,
  IconSignOut,
  IconTimesCircle
} from '@infologistics/frontend-libraries'

import objectsService from '@services/objects'

import { ObjectStates, ObjectTypes } from '@const/translations'

import { AllProps, IItemPropsFromState as IPropsFromState } from '@views/archive/components/Archive/Item/types'

import { IApplicationState } from '@store/types/commonTypes'
import { ICustomField } from '@store/modules/accounts/types'

import { getObjectTypeIcon, showErrorNotification } from '@utils/utils'

import { ObjectType, SuccessCode } from '@const/consts'
import cn from 'classnames'
import SealTooltip from '@common/SealTooltip'

const style = require('../Archive.module.css')

export const stateToIcon = {
  closed: <IconTimesCircle color='danger-500' />,
  destroyed: <IconFire color='danger-500' />,
  in: <IconSignIn color='success-500' />,
  out: <IconSignOut rotation='180' color='warning-500' />,
  pending: <IconQuestion color='danger-500' />,
  question: <IconQuestion color='danger-500' />
}

const Item = (props: AllProps): ReactElement => {
  const {
    account,
    alt_barcode,
    barcode,
    checkbox,
    first_state_in_date,
    is_connected,
    location,
    notes,
    object_transport,
    object_type,
    files,
    oguid,
    seal1,
    seal2,
    onChangeCurrentObject,
    path,
    state,
    stationary_location,
    sub_account,
    terminal,
    index,
    fields,
    customFields,
    isClientOrMasterClient,
  } = props

  const { t } = useTranslation(['object', 'common'])
  const showSeals = !!(seal1 ?? seal2)

  const additionalFields = (): ReactElement[] => {
    return customFields.map((item: ICustomField) => {
      let fieldValue = null
      switch (item.field_type) {
        case 'date':
          fieldValue = fields[item.field_code] && getFormattedDate(fields[item.field_code])
          break
        case 'datetime':
          fieldValue = fields[item.field_code] && getFormattedDateAndTime(fields[item.field_code])
          break
        case 'boolean':
          fieldValue = fields[item.field_code] ? t('common:yes') : t('common:no')
          break
        default:
          fieldValue = fields[item.field_code]
      }

      return (
        <td key={item.oguid}>
          <Ellipsis>{fieldValue}</Ellipsis>
        </td>
      )
    })
  }

  const handleImportFiles = (): void => {
    if (files) {
      objectsService
        .getObjectFiles(oguid)
        .then((resp: AxiosResponse<Blob>) => {
          const { status, data } = resp;

          if (status === SuccessCode.GET) {
            fileDownload(
              data,
              files?.length > 1 ? barcode + '.zip' : files[0].filename
            );
          }
        })
        .catch(showErrorNotification);
    }
  };

  return (
    <tr onClick={onChangeCurrentObject(oguid)} className={cn(style.row, 'relative')}>
      <td className={style.cellWidth28}>{checkbox}</td>
      <td className={style.cellWidth50}>{index}</td>
      <td className={style.cellWidth26} title={t(ObjectStates[state])}>
        {stateToIcon[state]}
      </td>
      <td className={style.cellWidth26} title={t(ObjectTypes[object_type])}>
        {getObjectTypeIcon(object_type, t, 'mx-auto')}
      </td>
      <td className={style.cellWidth26}>
        {(object_type === ObjectType.BOX) && showSeals && <SealTooltip seal1={seal1} seal2={seal2} />}
      </td>
      <td className={style.cellWidth115}>{barcode}</td>
      <td className={style.cellWidth115} title={alt_barcode}>
        <Ellipsis>{alt_barcode}</Ellipsis>
      </td>
      <td
        className={style.cellWidth26}
        title={files?.length ? t('object:files_attached') : ''}
      >
        {files?.length ? <IconAttach onClick={handleImportFiles} /> : <></>}
      </td>
      <td className={style.cellWidth400} title={notes}>
        <Ellipsis>{notes}</Ellipsis>
      </td>
      {!isClientOrMasterClient && (
        <>
          <td className={style.cellWidth160}>{location}</td>
          <td className={style.cellWidth160}>{stationary_location}</td>
        </>
      )}
      <td className={style.cellWidth160} title={is_connected ? t('object:attached') : ''}>
        <div className={style.flex}>
          {is_connected && <IconChain />}
          {path}
        </div>
      </td>
      <td className={style.cellWidth240} title={account?.name}>
        <Ellipsis>{account?.name}</Ellipsis>
      </td>
      <td className={style.cellWidth200} title={sub_account?.name}>
          <Ellipsis>{sub_account?.name}</Ellipsis>
      </td>
      {!isClientOrMasterClient && (
        <td className={style.cellWidth100}>{object_transport?.barcode}</td>
      )}
      <td className={style.cellWidth160} title={terminal?.name}>
        <Ellipsis>{terminal?.name}</Ellipsis>
      </td>
      <td className={style.cellWidth80}>{first_state_in_date && getFormattedDate(first_state_in_date)}</td>
      {additionalFields()}
    </tr>
  )
}

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  isClientOrMasterClient: state.organizations.activeOrganization.isClientOrMasterClient,
})

const ItemFunc = connect(mapStateToProps)(Item)

export default ItemFunc
