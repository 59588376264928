import { AxiosRequestConfig, AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { IParams, ISearchParams, ISuccessfulRequest } from '@store/types/commonTypes'
import {
  INewObject,
  IObject,
  IObjectHistoryElement, IObjectsWithStatuses,
  IUpdatedObjects,
  IUpdateObject
} from '@store/modules/objects/types'

class ObjectsService extends AbstractHttpService {
  private readonly url = urls.objects

  // https://api-rmk-backend-dev.dev.info-logistics.eu/specification/swagger.html#/object/getObject
  public async getObject (objectOguid: string): Promise<AxiosResponse<IObject>> {
    const url = this.url.single.replace('{{ oguid }}', objectOguid)

    return await this._http.get(url)
  }

  public async getObjects (params: IParams | ISearchParams): Promise<AxiosResponse<IObject[]>> {
    const url = this.url.list
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }

  public async getRequestObjects (
    params: IParams,
    requestOguid: string
  ): Promise<AxiosResponse<IObjectsWithStatuses>> {
    const config: AxiosRequestConfig = { params }
    const url = urls.requests.objects.replace('{{ oguid }}', requestOguid)

    return await this._http.get(url, config)
  }

  public async getObjectsWithStatuses (url: string, params: IParams): Promise<AxiosResponse<IObjectsWithStatuses>> {
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }

  // https://api-rmk-backend-dev.dev.info-logistics.eu/specification/swagger.html#/object/getObjectFiles
  public async getObjectFiles (objectOguid: string): Promise<AxiosResponse<Blob>> {
    const url = this.url.file.multi.replace('{{ oguid }}', objectOguid)
    const config: AxiosRequestConfig = { responseType: 'blob' }

    return await this._http.get(url, config)
  }

  // https://api-rmk-backend-dev.dev.info-logistics.eu/specification/swagger.html#/object/getObjectsFiles
  public async getObjectsFiles(
    orderID: string,
    data: string[]
  ): Promise<AxiosResponse<Blob>> {
    const url = this.url.file.objectsFiles;

    const params: IParams = { order_id: orderID };
    const config: AxiosRequestConfig = {
      params,
      headers: {
        accept: 'multipart/form-data',
      },
      responseType: 'blob',
    };

    return await this._http.post(url, data, config);
  }

  // https://api-rmk-backend-870.dev.info-logistics.eu/specification/swagger.html#/object/getObjectFile
  public async getObjectFile(
    objectOguid: string,
    fileOguid: string
  ): Promise<AxiosResponse<Blob>> {
    const url = this.url.file.single
      .replace('{{ oguid }}', objectOguid)
      .replace('{{ file_oguid }}', fileOguid);

    const config: AxiosRequestConfig = { responseType: 'blob' }

    return await this._http.get(url, config)
  }

  // https://api-rmk-backend-dev.dev.info-logistics.eu/specification/swagger.html#/object/deleteObjectFile
  public async deleteObjectFile(
    objectOguid: string,
    fileOguid: string
  ): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.file.single
      .replace('{{ oguid }}', objectOguid)
      .replace('{{ file_oguid }}', fileOguid);

    return await this._http.delete(url);
  }

  public async sendFile (data: any, objectOguid: string): Promise<AxiosResponse<Blob>> {
    const url = this.url.file.addFile.replace('{{ oguid }}', objectOguid)

    return await this._http.post(url, data)
  }

  public async getTemplate (params: ISearchParams, create?: boolean): Promise<AxiosResponse<Blob>> {
    const url = create ? this.url.children.template : this.url.file.template
    const config: AxiosRequestConfig = { responseType: 'blob', params }

    return await this._http.get(url, config)
  }

  public async exportArchiveObjects (filters: object): Promise<AxiosResponse<Blob>> {
    const url = this.url.file.list
    const config: AxiosRequestConfig = { params: filters, responseType: 'blob' }

    return await this._http.get(url, config)
  }

  public async addObject (url: string, data: INewObject[]): Promise<AxiosResponse<ISuccessfulRequest>> {
    return await this._http.post(url, data)
  }

  public async updateObject (objectOguid: string, data: IUpdateObject): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.single.replace('{{ oguid }}', objectOguid)

    return await this._http.put(url, data)
  }

  public async updateOrCreateObjects (data: any, createType?: string): Promise<AxiosResponse<IUpdatedObjects>> {
    const url = createType ? this.url.children.file : this.url.file.list

    const params: IParams = {
      object_type: createType
    }
    const config: AxiosRequestConfig = { params }

    return await this._http.put(url, data, config)
  }

  public async deleteObject (url: string, data?: string[]): Promise<AxiosResponse<ISuccessfulRequest>> {
    const config: AxiosRequestConfig = { data }

    return await this._http.delete(url, config)
  }

  public async getObjectHistory (objectOguid: string, params: IParams): Promise<AxiosResponse<IObjectHistoryElement[]>> {
    const url = this.url.history.replace('{{ oguid }}', objectOguid)
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }
}

export default new ObjectsService()
